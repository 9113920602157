import {
  AfterViewInit,
  Component,
  HostListener,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { CONSTANTS } from '../app/_services/constants';
import { DataService } from '../app/_services/data.service';
import { AuthService } from './_services/auth.service';
import getPdlJobAdName from './_services/shared/getPdlJobAdName';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  title = 'talaneo-web';

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.dataService.isMobile = event.target.innerWidth < 943;
    // || event.target.innerWidth < event.target.innerHeight;
    this.dataService.isMobileObservable.next(this.dataService.isMobile);
    console.log('isMobileUpdate', this.dataService.isMobile);
  }

  constructor(
    public dataService: DataService,
    private router: Router,
    private authService: AuthService,
  ) {
    this.authService.production = environment.production;

    const tags: any = [
      { charset: 'UTF-8' },
      {
        name: 'keywords',
        content: 'Personalvermittlung, Job, Jobsuche, Jobwechsel, Personaldienstleistung, Finanzen, Rechnungswesen, Controlling, HR, Personal, Buchhaltung, Bilanzbuchhaltung, Personalleitung,'
      },
      { name: 'author', content: 'talaneo' },
    ];

    if (this.authService.production) {
      tags.push({
        name: 'robots',
        content: this.authService.production
          ? 'index, follow'
          : 'noindex, nofollow',
      });
    }
    
    this.authService.setTitle(null, tags);

    this.dataService.isMobile = window.innerWidth < 943;
    // || window.innerWidth < window.innerHeight;
    this.dataService.isMobileObservable.next(this.dataService.isMobile);
    console.log('isMobile', this.dataService.isMobile);

    this.authService.pdlId = this.authService.production
      ? 'wBKjUV7oTDgurveIAZv7yQY33333'
      : 'GkTOyRGEzUVyolJQIXUySjBv9uU2';
    CONSTANTS.elastic = {
      jobAdIndex: getPdlJobAdName(
        this.authService.pdlId,
        this.authService.production
      ),
      node: 'https://karriereheld.es.europe-west3.gcp.cloud.es.io:9243',
      apiKey: 'Z2RLVzNIMEJzRkVJc3EyZ2huQUM6TWFMaEpyNHFTSW05TlRZMjZrT2dmdw==',
    };


    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const splitted = event.url.split('/');
        const url = splitted[1];

        switch (url) {
          case '':
            this.dataService.currentPage = 'home';
            break;
          case 'job-ad':
          case 'jobs':
            this.dataService.currentPage = 'job-ad';
            break;
          case 'companies':
          case 'unternehmen':
            this.dataService.currentPage = 'companies';
            break;
          case 'dashboard':
            this.dataService.currentPage = 'dashboard';
            break;
          case 'about':
            switch (splitted[2]) {
              case 'faq':
                this.dataService.currentPage = 'faq';
                break;
              case 'contact':
                this.dataService.currentPage = 'contact';
                break;
            }
            break;
        }
      }
    });

    this.authService.initAuth();
    this.dataService.loadStaticPDL(this.authService.pdlId);
    this.dataService.loadPredictions(this.authService.pdlId);
  }

  ngAfterViewInit() {
    this.dataService.showPreloader = false;
  }

  navigate(path: string) {
    this.router.navigate([path]);
    this.dataService.showMenu = false;
  }

  openKandiboard() {
    window.open('https://talaneo.kandiboard.de/bewerben', '_blank');
  }
}
