// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    useLocal: false,
    apiKey: "AIzaSyC-G5r0bi9q6pD-K10W7rob0u7hK0T_ulY",
    authDomain: "kandi-board-dev.firebaseapp.com",
    projectId: "kandi-board-dev",
    storageBucket: "kandi-board-dev.appspot.com",
    messagingSenderId: "934247968102",
    appId: "1:934247968102:web:7e1b95b101182d1ee72ca7"
  },
  GOOGLE_MAPS_API_KEY: 'AIzaSyCSBdelPVH7lPFd3dIMUdWmr7xZGa3slIY',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
