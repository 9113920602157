import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { UserSetStaticComponent } from './_services/user-set-static/user-set-static.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./auth/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'affiliate',
    loadChildren: () => import('./affiliate/affiliate.module').then((m) => m.AffiliateModule),
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./about/about.module').then((m) => m.AboutModule),
  },
  {
    path: 'job-ad',
    loadChildren: () =>
      import('./job-ad/job-ad.module').then((m) => m.JobAdModule),
  },
  {
    path: 'jobs',
    loadChildren: () =>
      import('./job-ad/job-ad.module').then((m) => m.JobAdModule),
  },
  {
    path: 'legal',
    loadChildren: () =>
      import('./legal/legal.module').then((m) => m.LegalModule),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./error/error.module').then((m) => m.ErrorModule),
  },
];
if (!environment.production) {
  routes.push({ path: 'static', component: UserSetStaticComponent });
}

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
